import "./styles.css";
export default function App() {
  return (
    <div body>
      <div className="App">
        <h1>Cock and balls</h1>
        <br />
        <h3>
          GIANT WEINER <br />
          <img src="https://raw.githubusercontent.com/jordan-trempert/media/main/mp4.gif"></img>
          <iframe
            width="10"
            height="10"
            src="https://www.youtube.com/embed/elsx60Y0WF0?controls=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay;
clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <br />
        </h3>
        <img src="https://raw.githubusercontent.com/jordan-trempert/media/main/weird.gif"></img>
        <iframe
          width="10"
          height="10"
          src="https://www.youtube.com/embed/elsx60Y0WF0?controls=0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay;
        clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
}
